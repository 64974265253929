
#zoomicon {
    size: 15px;
}

#zoomdropdown {
    padding-top: 5px
}

#sharemarks {
    width: 200px;
    text-align: center;
    z-index: 0;
}

.w1024 {
    max-width: 1024px;
    max-height: 1024px;
    margin: auto;
}

.zonecnt {
    margin-top: min(10px, 1vw);
    position: relative;
}
.zone {
    border: #fff solid 5px;
}
.mol {
    position: absolute;
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    z-index: 3;
}

.hunt-options {
    position: sticky;
    top: 0;
    z-index: 3
}

.hunt-options-poof {
}

