body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    background: url(/static/media/siren-hunts.25c95166.png) no-repeat top center #1c1f33 !important;
    background-size: cover !important;
}

#home {
    position: absolute;
    top: 0;
    left: 0
}

.logo-siren {
    margin-top: auto;
    margin-bottom: auto;
    width: min(50px, 5vw);
    height: min(50px, 5vw);
    max-width: 50px;
    max-height: 50px;
}

.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root {
    max-height: 100%;
    max-width: 100%;
}

.photo {
    width: 100%;
    height: min(300px, 30vw);
    max-height: min(300px, 30vw);
    object-fit: cover;
    display: inline-block;
    margin: auto;
    border-radius: 75px;
    position: center;
    object-position: center 0;
}

.char-photo {
    width: 100%;
    height: min(400px, 40vw);
    max-width: min(400px, 40vw);
    object-fit: cover;
    position: center;
    object-position: center 0;
}

.grid-about {
    height: min(400px, 40vw);
    max-width: min(400px, 40vw);
    background: #545461;
    padding: min(5px, .5vw);
    overflow: auto;
}

.grid-background {
    background: #484b59;
    padding: min(30px, 3vw);
}

.admin-title-container h1 {
    text-align: center;
    color: #ffffff;
    font-size: min(60px, 6vw);
    -webkit-text-stroke: 2px black;
    padding: min(15px, 1.5vw);
}

.grid-card {
    color: #ffffff;
    position: relative;
}

.grid-text {
    font-size: min(30px, 3vw);
    font-family: impact;
    -webkit-text-stroke: 1px black;
}

.grid-roles-text {
    font-size: min(16px, 1.6vw);
    font-family: impact;
    -webkit-text-stroke: 1.2px black;
}

.grid-roles-bottom {
    position: absolute;
    top: min(4px, .4vw);
    left: min(8px, .8vw);
}

.grid-roles-top {
    position: absolute;
    bottom: min(4px, .4vw);
    left: min(8px, .8vw);
}

/* Bottom left text */
.grid-bottom-left {
    position: absolute;
    bottom: min(8px, .8vw);
    left: min(16px, 1.6vw);
}

/* Top left text */
.grid-top-left {
    position: absolute;
    top: min(8px, .8vw);
    left: min(16px, 1.6vw);
}

/* Top right text */
.grid-top-right {
    position: absolute;
    top: min(8px, .8vw);
    right: min(16px, 1.6vw);
    text-align: right;
}

/* Bottom right text */
.grid-bottom-right {
    position: absolute;
    bottom: min(8px, .8vw);
    right: min(16px, 1.6vw);
    text-align: right;
}

/* Centered text */
.grid-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-text {
    color: #ffffff;
    -webkit-text-stroke: .5px black;
    margin-top: min(50px, 5vw);
}


div#basic-navbar-nav {
    z-index: 5 !important;
}



#zoomicon {
    size: 15px;
}

#zoomdropdown {
    padding-top: 5px
}

#sharemarks {
    width: 200px;
    text-align: center;
    z-index: 0;
}

.w1024 {
    max-width: 1024px;
    max-height: 1024px;
    margin: auto;
}

.zonecnt {
    margin-top: min(10px, 1vw);
    position: relative;
}
.zone {
    border: #fff solid 5px;
}
.mol {
    position: absolute;
    display: block;
    border: none;
    padding: 0;
    margin: 0;
    z-index: 3;
}

.hunt-options {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3
}

.hunt-options-poof {
}


